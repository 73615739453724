<template>
  <section class="custom-hero-section">
    <h1 class="page-title">Advisory and Consulting for Startups</h1>
  </section>
</template>

<script setup>
</script>

<style scoped>
.custom-hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  height: 50vh;
  color: white;
  background: #141726;
} 

.custom-hero-section .page-title {
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: inherit;
  font-family: Lato;
}

.custom-hero-section h3 {
  margin-bottom: 10px;
  font-size: 2rem;
  color: inherit;
}

.custom-hero-section p {
  font-size: 1.4rem;
  margin-bottom: 20px;
  max-width: 80%;
  color: inherit;
}

.page-title {
  font-family: "Over the Rainbow", cursive;
}
</style>
