<template>
  <div class="diverse-teams">
    <h3 class="content-title">Leading Diverse Teams and Building Inclusive Environments</h3>
    <p>As a leader of a diverse team or someone aspiring to build an inclusive environment, understanding and addressing biases is pivotal for effectiveness and positive change. Recognizing the challenges faced by underrepresented groups in the professional landscape, including systemic and cultural biases in funding, opportunity, and access, is crucial. My coaching approach is designed to help you become aware, better understand, and take action in dismantling these biases. By embracing this awareness, you not only become a more effective leader but also contribute to making the world a better and more inclusive place. Together, let's create workplaces and a world where everyone has equal opportunities to thrive and succeed.</p>
  </div>
</template>

<style scoped>
.diverse-teams {
  border: 2px solid #141726;
  padding: 20px; 
  text-align: justify; 
  margin: 40px; 
  background-color: #fff; 
}

.content-title {
  color: #333; 
  margin-bottom: 15px; 
  text-align: center;
}

.diverse-teams p {
  color: #666; 
  line-height: 1.6; 
}

@media (max-width: 768px) {
  .diverse-teams {
    margin: 20px 10px; 
  }
}
</style>
